table {
  border-collapse: separate;
  border-spacing: 0px;
  border-width: 10px;
}

td {
  padding: 10px 0;
}

th {
  min-width: 50px;
}

.tag-row-sync {
  background-color: aliceblue;
}

.new-tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
}