.plan-selection {
  display: flex;
  justify-content: space-around;
  background-color: #f4f5f7;
  padding: 20px;
  border-radius: 8px;
}

.plan {
  background-color: #ffffff;
  border: 1px solid #d1d3d4;
  border-radius: 4px;
  padding: 20px;
  width: 100%; /* Adjust as needed */
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.selected {
  background-color: green;
}

.currentPlan {
  background-color: grey;
  cursor: unset !important;
}

.plan-header h2 {
  color: #333;
  margin-bottom: 10px;
}

.plan-header p {
  color: #666;
  line-height: 1.4;
}

.plan-price {
  font-size: 24px;
  font-weight: bold;
  margin: 20px 0;
}

.plan-features {
  list-style-type: none;
  padding: 0;
  margin: 10px 0 0 0;
}

.plan-features li {
  margin: 0;
  padding: 8px 0;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.plan-features li.included {
  margin: 0;
  color: #333;
}

.button-primary {
  background-color: #ffcd00;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 4px;
  cursor: pointer;
}

.button-primary:hover {
  background-color: #ffdf00;
}

.Overlay {
  position: fixed; /* Fixed positioning removes the element from the normal flow and positions it relative to the viewport */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 1000; /* High z-index to ensure it's on top of other content */
}

.Modal {
  position: fixed; /* Same as the overlay to ensure it's positioned in relation to the viewport */
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); /* Centers the modal */
  z-index: 1001; /* Higher than the overlay to ensure the modal content is on top */
  /* Rest of your styles */
}

.modal-close-button {
  position: absolute;
  top: -15px;
  right: -10px;
  background: none;
  border: none;
  font-size: 1.5em;
  cursor: pointer;
  color: #000;
}

.modal-close-button:hover {
  color: #f00; /* Optional: change color on hover */
}


/* You might want to add more styles or classes based on the plans' specificities */
