
.editable:hover {
  cursor: text;
  background-color: #d7d7d7;
}

.editable-textbox {
  font-family: monospace;
  font-size: 28px;
  display: inline-block;
}

.editable-datebox {
  font-family: monospace;
  font-size: 28px;
  display: inline-block;
  width: 11ch;
}
