/* Loader.css */

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 25vh; /* Padding from the top */
  box-sizing: border-box; /* Includes padding in the height calculation */
  font-size: 1.5rem;
  color: #333;
}
