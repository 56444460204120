/* Settings.css */

.settings-container {
  display: flex;
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.selected-tab {
  background-color: #000;
  cursor: none;
}

.sidebar {
  flex: 1;
  max-width: 200px;
  margin-right: 20px;
}

.sidebar button {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  border-radius: 4px;
  cursor: unset;
}

.tab {
  background-color: #666;
  cursor: pointer;
}

.tab:hover {
  background-color: #9f9f9f;
  cursor: pointer;
}

.logout-tab {
  background-color: #666;
  cursor: pointer;
}

.logout-tab:hover {
  background-color: #efa0a0;
  cursor: pointer;
}

.logout-tab:active {
  background-color: #c73030;
  cursor: pointer;
}

.content {
  flex: 3;
}

.content h2 {
  margin-bottom: 15px;
}

form label {
  display: block;
  margin-bottom: 10px;
}

form input {
  width: 100%;
  padding: 8px;
  margin-top: 5px;
  margin-bottom: 15px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 10px 15px;
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
}
