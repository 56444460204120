/* MyJournals.css */

.my-journals {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
}

.sorting-options {
  margin-bottom: 20px;
}

.sorting-options button {
  margin-right: 10px;
  padding: 5px 10px;
  cursor: pointer;
}

.journal-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.journal-card {
  width: calc(33.333% - 10px);
  max-width: 377px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  overflow: hidden;
}

.journal-card img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
  max-height: 282px;
}

.journal-info {
  padding: 15px;
  background: white;
}

.journal-info h3 {
  font-size: 1rem;
  margin: 0;
}

.journal-info p {
  font-size: 0.8rem;
  color: #666;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.pagination button {
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .journal-card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .journal-card {
    width: 100%;
  }
}
