/* SearchPage.css */

.search-page {
  padding: 20px;
  max-width: 800px;
  margin: auto;
}

.search-page form {
  margin-bottom: 20px;
}

.search-page input, .search-page button {
  padding: 8px;
  margin-right: 5px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.search-page button {
  cursor: pointer;
  background-color: #4CAF50;
  color: white;
  border: none;
}

.filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.results .result-item {
  border-bottom: 1px solid #eee;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.4);
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.results h3 {
  margin: 0 0 10px 0;
}

.results p {
  margin: 0 0 10px 0;
}

.results video {
  width: 100%;
  max-height: 200px;
}

.results .result-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}

.results img {
  width: 150px; /* Adjust as needed */
  height: auto;
  margin-right: 10px;
}

.result-info h3, .result-info p {
  margin: 0;
}