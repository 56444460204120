.transcription-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.transcription-body {
  max-height: 15rem;
  overflow: auto;
}

.transcript-word {
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
  padding: 5px;
  font-family: monospace;
}

.transcription span {
  margin-right: 0px;
}

.editable-word {
  margin: 5px 4px;
  padding: 1px;
  cursor: pointer;
  display: inline-block;
  border-radius: 10px;
  background-color: #f0f0f0;
}

.editable-word input {
  border: none;
  text-align: center;
  border-radius: 10px;
  width: auto;
  font-family: monospace;
  background-color: #f0f0f0;
}
