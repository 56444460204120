/* Navbar.css */

.navbar {
  display: flex;
  align-items: center;
  background-color: #333;
  color: white;
  padding: 0.5rem 1rem;
  position: fixed; /* Fixes the navbar at the top of the viewport */
  top: 0; /* Aligns the navbar to the top edge of the viewport */
  left: 0; /* Aligns the navbar to the left edge of the viewport */
  right: 0; /* Ensures the navbar stretches across the full width */
  z-index: 1000; 
}

@media (max-width: 430px) {
  .navbar {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.nav-logo {
  flex: 1; /* Allocates a flex portion to the logo */
  color: white;
  text-decoration: none;
  font-size: 1.5rem;
  font-weight: bold;
}

.nav-links {
  display: flex;
  justify-content: center;
  flex: 2; /* Allocates a larger flex portion to the nav links */
}

.nav-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px; /* Adjust spacing as needed */
  font-size: 1rem;
}

.nav-links a:hover {
  text-decoration: underline;
}

.login-nav-links {
  display: flex;
  justify-content: flex-end;
  flex: 2; /* Allocates a larger flex portion to the nav links */
}

.login-nav-links a {
  color: white;
  text-decoration: none;
  margin: 0 10px; /* Adjust spacing as needed */
  font-size: 1rem;
}

.login-nav-links a:hover {
  text-decoration: underline;
}

.search-form {
  display: flex;
  flex: 1; /* Allocates a flex portion to the search form */
  justify-content: flex-end; /* Aligns the search form to the right */
}

.search-form input {
  padding: 0.5rem;
  margin-right: 0.5rem;
  border: none;
  border-radius: 4px;
  margin-bottom: 0px;
  margin-top: 0px;
  line-height: normal;
}

.search-form button {
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background-color: #555;
  color: white;
  line-height: normal;
}

.search-form button:hover {
  background-color: #666;
}
