/* Upload.css */

.upload-container {
  padding: 20px;
  max-width: 600px;
  margin: auto;
}

.upload-container input {
  width: 100%;
  margin-bottom: 20px;
}

.required-input {
  border: 2px solid red;
}

.file-drop-area {
  border: 2px dashed #ccc;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
}

.file-drop-area:hover {
  background-color: #f9f9f9;
}

.file-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #eee;
  border-radius: 4px;
}

.tags-container {
  margin-bottom: 20px;
}

.tag {
  display: inline-block;
  background-color: #eef;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

button {
  padding: 10px 15px;
  margin-right: 10px;
  cursor: pointer;
}

.disabled-button {
  background-color: #737373;
}

.disabled-button:hover {
  cursor: default;
}

.enabled-button {
}

.enabled-button:hover {

}

.progress {
  width: 100%;
  margin-bottom: 20px;
}

.video-recording {
  margin-bottom: 20px;
}

.upload-title {
  display: flex;
}

video {
  width: 100%;
  max-height: 300px;
  margin-bottom: 20px;
}

.mirrored {
  transform: scaleX(-1); /* Flips the video horizontally */
}
