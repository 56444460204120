
.dropdown-toggle::after {
  display: none !important; 
  background-color: grey;
}

.btn::hover {
  background-color: grey !important;
}

.btn {
  --bs-btn-color: black;
  --bs-btn-bg: none;
  --bs-btn-border-color: none;
  --bs-btn-hover-color: black;
  --bs-btn-hover-bg: grey;
  --bs-btn-hover-border-color: grey;
  --bs-btn-active-color: black;
  --bs-btn-active-bg: grey;
  --bs-btn-active-border-color: grey;
}

.remove-item {
  --bs-dropdown-link-hover-bg: #efa0a0;
  --bs-dropdown-link-active-bg: #c73030;
  --bs-dropdown-link-active-color: black;
}