.chip {
  display: inline-block;
  padding: 2px 8px;
  margin-right: 5px;
  border-radius: 5px;
  margin-bottom: 5px;
}

.chip-token {
  font-size: 0.8rem;
  margin: 0px;
  padding: 0px;
}

.chip-token:hover {
  cursor: pointer;
}