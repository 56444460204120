/* JournalEntry.css */

.journal-entry {
  /* Your existing styles... */
  max-width: 800px;
  margin: auto;
  padding: 20px;
}

.transcription p {
  word-break: keep-all;
  overflow-wrap: break-word;
  white-space: normal;
}

.transcription span {
  display: inline-block; /* Changed from inline to inline-block */
  margin-right: 5px;
  cursor: pointer;
}


.video-player {
  width: 100%;
  max-height: 450px;
  margin-bottom: 20px;
}

.transcription {
  margin-bottom: 60px;
}

.transcription-line {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.timestamp {
  margin-right: 10px;
  font-family: monospace;
  color: #555;
}

.transcription-line input {
  flex: 1;
  padding: 5px;
  margin-right: 10px;
}

.save-changes {
  display: flex;
  justify-content: flex-end;
}

.notes-input {
  width: 100%;
  height: 100px;
  padding: 10px;
}

.tags {
  margin-bottom: 20px;
  padding: 0px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

button {
  padding: 10px 15px;
  cursor: pointer;
}

.video-header-fields {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.video-header-with-dropdown {
  display: flex;
  justify-content: space-between;
}

.feed {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.feedPost {
    display: block;
    padding: 10px 5px 30px 5px;
    border: black;
    border-bottom-width: thin;
    border-top-style: dashed;
    width: 100%;
}