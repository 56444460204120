/* Signup.css */

.signup-container {
  max-width: 400px;
  margin: auto;
  padding: 1rem;
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.signup-container form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.signup-container input,
.signup-container select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.signup-container button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.signup-container button:hover {
  background-color: #0056b3;
}
