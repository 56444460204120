/* Dashboard.css */

.dashboard {
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  text-align: center;
}

.summary {
  margin-bottom: 30px;
  font-size: 1.2rem;
  color: #555;
}

.quick-stats {
  margin-bottom: 30px;
  background: #f8f8f8;
  padding: 10px;
  border-radius: 8px;
}

.quick-stats p {
  margin: 10px 0;
  color: #333;
}

.recent-entries {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;
}

.entry-card {
  width: calc(33.333% - 10px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  border-radius: 8px;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
}

.entry-card img {
  width: 100%;
  height: auto;
  display: block;
}

.entry-info {
  padding: 15px;
  background: white;
}

.entry-info h3 {
  font-size: 1rem;
  margin: 0;
}

.entry-info p {
  font-size: 0.8rem;
  color: #666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .entry-card {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  .entry-card {
    width: 100%;
  }
}
